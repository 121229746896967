const messageReducer = (state = [], action) => {
    switch (action.type) {
        case 'ADD_MESSAGE': {
            const message = {
                id: action.id,
                content: action.message,
                type: action.messageType,
                isAutoHide: action.isAutoHide,
            };
            return state.concat(message);
        }
        case 'DEL_MESSAGE': {
            return state.filter(message => message.id !== action.id)
        }
        default : {
            return state;
        }
    }
};

export default messageReducer;