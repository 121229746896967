const reducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_ALL_OPERATIONS': {
            return action.operations;
        }
        default : {
            return state;
        }
    }
};

export default reducer;