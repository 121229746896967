const initState = {
    code: '',
    params: null,
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_OPERATION': {
            return {
                code: action.code,
                name: action.name,
                params: action.params,
            }
        }
        default: {
            return state;
        }
    }
};

export default reducer;