import {Component} from "react";
import Button from "@material-ui/core/Button/Button";
import React from "react";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Typography from "@material-ui/core/Typography/Typography";
import withStyles from "@material-ui/core/es/styles/withStyles";
import {connect} from 'react-redux';

const M = 1024 * 1024;

const styles = () => ({
    root: {
        textAlign: 'center',
    },
    imagePreview: {
        width: '100%'
    },
});

class ImgSelector extends Component {
    state = {
        open: false,
        image: null,
        error: null,
        isChanged: false,
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleSubmit = () => {
        this.setState({open: false});
        this.props.onImageSubmit(this.state.image);
    };

    handleFile = e => {
        const image = e.target.files[0];
        if (!this.state.isChanged) {
            this.setState({
                isChanged: true,
            })
        }
        if (image) {
            if (!image.type.startsWith('image')) {
                this.setState({
                    image: null,
                    error: '请选择图片!',
                })
            } else if (!(image.size <= M)) {
                this.setState({
                    image: null,
                    error: '请选择大小小于1M的图片!',
                })
            } else {
                this.setState({
                    image
                })
            }
        } else {
            this.setState({
                image: null,
                error: '请选择图片!',
            })
        }
    };

    render() {
        const {isChanged, image, error} = this.state;
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Button variant='contained' color='primary' onClick={this.handleClickOpen}>上传图片</Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title" disableTypography>
                        <Typography variant='h6'>选择图片</Typography><Typography variant='caption'
                                                                              color='primary'>图片大小需小于1M</Typography>
                    </DialogTitle>
                    <DialogContent>
                        {/* 这里加了accept之后，可以不判断文件类型是否是image */}
                        {/* 这个accept可能会引起卡顿的问题 */}
                        <input type="file" accept='image/*' onChange={this.handleFile}/>
                        {isChanged ? image ? null : <Typography color='error'>{error}</Typography> : null}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            取消
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary" disabled={!image}>
                            提交
                        </Button>
                    </DialogActions>
                    {image ? <img src={URL.createObjectURL(image)} className={classes.imagePreview}/> : null}
                </Dialog>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onImageSubmit: image => {
            dispatch({
                type: 'SET_IMAGE',
                image: image,
            })
        }
    }
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(ImgSelector));