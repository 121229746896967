import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import {connect} from 'react-redux';

import OperationListItem from './OperationListItem';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        maxHeight: 650,
        overflow: 'auto',
    },
});

class NestedList extends React.Component {
    render() {
        const {classes, operations} = this.props;
        return (
            <div className={classes.root}>
                <List
                    component="nav"
                >
                    {operations.map((operation, index) => {
                        return <OperationListItem operation={operation} open={index === 0} key={operation.type}/>
                    })}
                </List>
            </div>
        );
    }
}

NestedList.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        operations: state.allOperations,
    }
};

export default connect(mapStateToProps)(withStyles(styles)(NestedList));