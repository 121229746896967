import React from "react";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Collapse from "@material-ui/core/Collapse/Collapse";
import List from "@material-ui/core/List/List";
import Avatar from "@material-ui/core/Avatar/Avatar";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import withStyles from "@material-ui/core/styles/withStyles";
import {connect} from 'react-redux';

const styles = theme => ({
    avatar: {
        marginRight: 20,
        width: 24,
        height: 24,
        fontSize: 18
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
});

class OperationListItem extends React.Component {
    state = {
        open: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            open: !!props.open
        }
    }

    handleOperationClick = subType => {
        this.props.setActiveOperation(subType);
    };

    handleClick = () => {
        this.setState({
            open: !this.state.open,
        })
    };


    render() {
        const {operation, classes, activeOperation} = this.props;
        const {subType: subTypes} = operation;
        return (
            <React.Fragment>
                <ListItem button onClick={this.handleClick}>
                    <ListItemIcon>
                        <Avatar>{operation.type.substr(0, 1)}</Avatar>
                    </ListItemIcon>
                    <ListItemText inset primary={operation.type} primaryTypographyProps={{variant:'subtitle1'}}/>
                    {this.state.open ? <ExpandLess/> : <ExpandMore/>}

                </ListItem>
                <Collapse in={this.state.open} timeout="auto">
                    <List component="div" disablePadding>
                        {subTypes.map(subType => {
                            return (
                                <ListItem button className={classes.nested} key={subType.code}
                                          selected={activeOperation.code === subType.code}
                                          onClick={() => {
                                              this.handleOperationClick(subType)
                                          }}>
                                    <ListItemIcon>
                                        <Avatar className={classes.avatar}>{subType.name.substr(0, 1)}</Avatar>
                                    </ListItemIcon>
                                    <ListItemText inset primary={subType.name} primaryTypographyProps={{variant:'subtitle1'}}/>
                                </ListItem>
                            );
                        })}
                    </List>
                </Collapse>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setActiveOperation(subType) {
            dispatch({
                type: 'SET_OPERATION',
                code: subType.code,
                name: subType.name,
                params: subType.params,
            })
        }
    }
};

const mapStateToProps = state => {
    return {
        activeOperation: state.activeOperation
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OperationListItem));