import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route} from 'react-router-dom'
import './index.css';
import Home from './Home';
import ImgExperiment from './ImgExperiment';
import {cards} from './sections'
import registerServiceWorker from './registerServiceWorker';
import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import reducers from './reducers';
import thunk from 'redux-thunk';
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import JssProvider from 'react-jss/lib/JssProvider';
import {create} from 'jss';
import {createGenerateClassName, jssPreset} from '@material-ui/core/styles';

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
    const {logger} = require('redux-logger');
    middlewares.push(logger);
}


const store = createStore(reducers, applyMiddleware(...middlewares));

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <JssProvider jss={jss} generateClassName={generateClassName}>
                <div className="main">
                    <CssBaseline/>
                    <Route exact path="/" component={Home}/>
                    <Route exact path={`/${cards[0].section}/${cards[0].operations[0]}`} component={ImgExperiment}/>
                </div>
            </JssProvider>
        </Router>
    </Provider>, document.getElementById('root')
);
registerServiceWorker();
