import {Component} from "react";
import Grid from "@material-ui/core/Grid/Grid";
import React from "react";
import withStyles from "@material-ui/core/es/styles/withStyles";
import {connect} from 'react-redux';
import OperationParamForm from './OperationParamForm';
import Typography from "@material-ui/core/Typography/Typography";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Chip from "@material-ui/core/Chip/Chip";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import SaveIcon from "@material-ui/icons/Save";
import CameraIcon from "@material-ui/icons/CameraAlt";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {ActionCreators} from 'redux-undo';
import SnackbarContent from './SnackbarContent';
import copyToClipboard from "copy-to-clipboard";
import {displayError, displayInfo, displayWarning} from "./util/Message";

const styles = theme => ({
    root: {
        display: 'flex',
        minWidth: 1100,
    },
    imgPreview: {
        width: '100%',
        cursor: 'zoom-in',
    },
    imgOriginal: {
        cursor: 'zoom-out',
    },
    imgBox: {
        overflow: 'scroll',
        maxHeight: 600,
        minWidth: 600,
        position: 'relative',
    },
    textField: {
        flexBasis: 280,
    },
    margin: {
        margin: theme.spacing.unit,
    },
    processArea: {
        height: 60,
    },
    processList: {
        height: 60,
        overflow: 'auto',
    },
    processAction: {
        textAlign: 'center'
    },
    paramArea: {
        flexShrink: 0,
        minWidth: 150,
    },
    chip: {
        margin: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    messageArea: {
        position: 'fixed',
        left: 'auto',
        right: 24,
        bottom: 24,
    },
    iconButtonWrapper: {
        display: 'inline-block',
    },
    cameraInput: {
        display: 'none',
    },
    cameraIconBox: {
        display: 'flex',
        width: '100%',
    }
});


class WorkingArea extends Component {
    state = {
        open: false,
    };

    handleClick = () => {
        this.setState({
            open: !this.state.open
        })
    };

    handleChipDelete = id => {
        this.props.deleteProcess(id);
    };

    handleChipClick = process => {
        console.log(process);
    };

    handleClickUndo = () => {
        this.props.undoProcess();
    };

    handleClickRedo = () => {
        this.props.redoProcess();
    };

    handleClickCloseMessage = id => {
        this.props.closeMessage(id)
    };
    handleClickSave = () => {
        this.props.clickSave(this.props.process.map(process => `${process.name}(${Object.entries(process.params).map(paramArray => paramArray.join(':')).join(',')})`));
    };
    handleCameraChange = e => {
        this.props.changeImage(e.target.files[0]);
    };

    render() {
        const {classes, image, isValidOperation, operation, process, hasFutureProcess, hasPastProcess, hasProcess, message, hasMessage} = this.props;
        const {open} = this.state;
        const imageUrl = process.length ? `data:image/jpeg;base64,${process[process.length - 1].processedImage}` : image.url;
        return (
            <Grid container className={classes.root} spacing={16}>
                <Grid item xs={10}>
                    <div className={classes.imgBox}>
                        <img className={open ? classes.imgOriginal : classes.imgPreview} src={imageUrl}
                             onClick={this.handleClick}/>
                    </div>
                    <Grid container className={classes.processArea}>
                        <Grid item xs={12} className={classes.processList}>
                            {process.map(process => {
                                const message = Object.entries(process.params).map(param => param.join(':')).join(' ');
                                return (
                                    <Tooltip title={message} key={process.id}>
                                        <Chip
                                            label={process.name}
                                            className={classes.chip}
                                            color="primary"
                                            onClick={() => {
                                                this.handleChipClick(process);
                                            }}
                                            onDelete={() => {
                                                this.handleChipDelete(process.id);
                                            }}
                                        />
                                    </Tooltip>
                                )
                            })}
                        </Grid>
                        <Grid item xs={12} className={classes.processAction}>
                            <Tooltip title='保存处理操作 保存图片请右键图片保存' enterDelay={500}>
                                <div className={classes.iconButtonWrapper}>
                                    <IconButton disabled={!hasProcess}
                                                onClick={this.handleClickSave}><SaveIcon
                                        fontSize='small'/></IconButton>
                                </div>
                            </Tooltip>
                            <Tooltip title='撤销' enterDelay={500}>
                                <div className={classes.iconButtonWrapper}>
                                    <IconButton disabled={!hasPastProcess}
                                                onClick={this.handleClickUndo}><UndoIcon
                                        fontSize='small'/></IconButton>
                                </div>
                            </Tooltip>
                            <Tooltip title='重做' enterDelay={500}>
                                <div className={classes.iconButtonWrapper}>
                                    <IconButton disabled={!hasFutureProcess}
                                                onClick={this.handleClickRedo}><RedoIcon
                                        fontSize='small'/></IconButton>
                                </div>
                            </Tooltip>
                            <Tooltip title='重选图片'>
                                <div className={classes.iconButtonWrapper}>
                                    <IconButton color="primary">
                                        <label htmlFor="fileInput"><span className={classes.cameraIconBox}><CameraIcon
                                            fontSize='small'/></span></label>
                                        <input id='fileInput' type="file" accept='image/*'
                                               className={classes.cameraInput} onChange={this.handleCameraChange}/>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={2} className={classes.paramArea}>
                    {isValidOperation ? <OperationParamForm operation={operation} key={operation.code}/> :
                        <Typography align='center' variant='h6'>请选择左侧操作</Typography>}
                </Grid>
                {
                    hasMessage &&
                    <div
                        className={classes.messageArea}>
                        {
                            message.map(message =>
                                <SnackbarContent variant={message.type} message={message.content}
                                                 isShowCloseIcon={!message.isAutoHide}
                                                 key={message.id}
                                                 onClose={() => this.handleClickCloseMessage(message.id)}
                                />
                            )
                        }
                    </div>
                }
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        image: state.image,
        isValidOperation: !!state.activeOperation.code,
        operation: state.activeOperation,
        process: state.process.present,
        hasProcess: state.process.present.length > 0,
        hasFutureProcess: state.process.future.length > 0,
        hasPastProcess: state.process.past.length > 0,
        message: state.message,
        hasMessage: state.message.length > 0,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        deleteProcess(id) {
            dispatch({
                type: 'DELETE_PROCESS',
                id,
            })
        },
        undoProcess() {
            dispatch(ActionCreators.undo());
        },
        redoProcess() {
            dispatch(ActionCreators.redo());
        },
        closeMessage(id) {
            dispatch({
                type: 'DEL_MESSAGE',
                id,
            })
        },
        clickSave(string) {
            copyToClipboard(string);
            displayInfo(dispatch, '已复制到剪贴板！');
        },
        changeImage(image) {
            if (image.size > 1024 * 1024) {
                return displayWarning(dispatch, '请选择大小小于1M的图片!');
            }
            dispatch({
                type: 'SET_IMAGE',
                image,
            });
            dispatch({
                type: 'CLEAR_PROCESS',
            });
            dispatch(ActionCreators.clearHistory());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WorkingArea));