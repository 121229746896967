import undoable, {includeAction} from 'redux-undo';

const initState = [];
let cnt = 1;

const reducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_PROCESS': {
            const newProcess = {
                id: cnt++,
                name: action.name,
                code: action.code,
                params: action.params,
                image: action.image,
                processedImage: action.processedImage,
                log: action.log,
            };
            return state.concat(newProcess);
        }
        case 'DELETE_PROCESS': {
            return state.filter(process => process.id < action.id);
        }
        case 'CLEAR_PROCESS': {
            return [];
        }
        default: {
            return state;
        }
    }
};

export default undoable(reducer, {
    filter: includeAction(['ADD_PROCESS', 'DELETE_PROCESS'])
});