function displayMessage(dispatch, message, type, isAutoHide) {
    const m = createMessage(message);
    dispatch({
        type: 'ADD_MESSAGE',
        ...m,
        messageType: type,
        isAutoHide,
    });
    if (isAutoHide) {
        setTimeout(() => {
            dispatch({
                type: 'DEL_MESSAGE',
                id: m.id,
            })
        }, 3000);
    }
}

function displayInfo(dispatch, message, isAutoHide = true) {
    displayMessage(dispatch, message, MessageType.Info, isAutoHide);
}

function displayWarning(dispatch, message, isAutoHide = true) {
    displayMessage(dispatch, message, MessageType.Warning, isAutoHide);
}

function displayError(dispatch, message, isAutoHide = true) {
    displayMessage(dispatch, message, MessageType.Error, isAutoHide);
}

const createMessage = ((cnt = 0) => message => {
    return {
        id: cnt++,
        message,
    }
})();

const MessageType = {
    Info: 'info',
    Warning: 'warning',
    Error: 'error',
};

export {
    MessageType,
    displayInfo,
    displayError,
    displayWarning,
};
