const pendingReducer = (state = 0, action) => {
    switch (action.type) {
        case 'ADD_PENDING': {
            return state + 1;
        }
        case 'DES_PENDING': {
            return state - 1;
        }
        default : {
            return state;
        }
    }
};

export default pendingReducer;