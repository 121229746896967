const reducer = (state = null, action) => {
    switch (action.type) {
        case 'SET_IMAGE': {
            return {
                image: action.image,
                url: URL.createObjectURL(action.image),
            }
        }
        default : {
            return state;
        }
    }
};

export default reducer;