import {combineReducers} from 'redux';
import imgReducer from './imgReducer';
import allOperationReducer from './allOperationReducer';
import activeOperationReducer from './activeOperationReducer';
import processReducer from './processReducer';
import pendingReducer from './pendingReducer';
import messageReducer from "./messageReducer";

export default combineReducers({
    image: imgReducer,
    allOperations: allOperationReducer,
    activeOperation: activeOperationReducer,
    process: processReducer,
    pending: pendingReducer,
    message: messageReducer,
})